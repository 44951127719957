export default {
  error_occurred: 'Lo sentimos, ha habido un error. Inténtalo dentro de un rato.',
  new_content_available: 'Hay una actualización. Haz clic en "Refrescar", para cargar la página de nuevo.',
  refresh_button_title: 'Refrescar',
  force_reload_message: 'Lo sentimos, ha ocurrido un error. Nuestro equipo ya está trabajando en ello. Intente recargar el sitio web en un momento.',
  sign_up: 'Crear cuenta',
  service: 'Servicio',
  employee: 'Empleado',
  select_employee: 'Seleccionar empleado',
  employees: 'Empleados',
  services: 'Servicios',
  duration: 'Duración',
  from: 'De',
  select_date: 'Seleccionar fecha',
  no_account_question: '¿Aún no tienes cuenta?',
  name: 'Nombre',
  surname: 'Apellidos',
  name_and_surname: 'Nombre y apellidos',
  phone: 'Teléfono',
  password: 'Contraseña',
  field_is_required: 'Campo obligatorio.',
  value_is_too_long: 'Texto demasiado largo.',
  value_is_too_short: 'Texto demasiado corto.',
  signup_was_successful: 'Tu cuenta ha sido creada con éxito. Conexión con el panel en curso.',
  signup_has_failed: 'Tu cuenta no ha sido creada. Reinténtalo dentro de un rato.',
  email_is_taken: 'Ya existe una cuenta para este e-mail. Si no te acuerdas de la contraseña, usa la opción de recuperarla.',
  email_or_password_incorrect: 'Dirección de e-mail o contraseña incorrecta. Si no te acuerdas de la contraseña, usa la opción de contraseña olvidada.',
  account_not_found: 'Cuenta no encontrada.',
  page_not_found: 'Vaya, página no encontrada :(',
  go_back_to_main_page: 'Vuelve al inicio e intenta de nuevo.',
  bookings: 'Citas',
  account: 'Mi cuenta',
  date: 'Fecha',
  cancel: 'Cancelar',
  date_from: 'Fecha desde',
  date_to: 'Fecha hasta',
  nothing_found_here: 'No se ha encontrado nada.',
  old_password: 'Contraseña antigua',
  remove: 'Eliminar',
  phone_is_invalid: 'Número incorrecto.',
  book: 'Reservar',
  sign_up_2: 'Crear cuenta',
  log_in: 'Iniciar sesión',
  your_email_address: 'Tu e-mail',
  select: 'Seleccionar',
  filters: 'Filtros',
  expand: 'Desplegar',
  collapse: 'Plegar',
  bookings_drawer_title: 'Selecciona los filtros, para mostrar solo las reservas que buscas.',
  clear_filters: 'Borrar filtros',
  show_more: 'Ver más',
  dont_you_remember_password: 'Contraseña olvidada',
  password_information: 'La contraseña debe tener al menos 8 caracteres, entre ellos: una mayúscula, un número y un carácter especial.',
  forgotten_password_button: 'Enviar link para cambiar contraseña',
  do_you_already_have_an_account: '¿Ya tienes una cuenta?',
  reset_password_title: 'Establecer nueva contraseña',
  reset_password_subtitle: 'Introduce tu nueva contraseña abajo.',
  reset_password_button: 'Guardar contraseña',
  profile: 'Perfil',
  logout: 'Cerrar sesión',
  cancel_booking: 'Cancelar cita',
  status_approved: 'Confirmado',
  status_payment: 'Pendiente de pago',
  status_done: 'Terminado',
  status_cancelled: 'Cancelado',
  cancel_booking_dialog_title: '¿Quieres cancelar la cita?',
  cancel_booking_dialog_information: '¡Esta acción es irrevocable!',
  cancel_booking_dialog_button: 'Sí, cancelar cita',
  my_profile: 'Mi perfil',
  my_data: 'Mis datos',
  upload_new_photo: 'Añadir nueva imagen',
  birthday: 'Fecha nacimiento',
  save_changes: 'Guardar cambios',
  phone_number: 'Número de teléfono',
  directional: 'Prefijo',
  save_new_password: 'Guardar nueva contraseña',
  new_password: 'Nueva Contraseña',
  password_change: 'Cambio de contraseña',
  view: 'Ver',
  services_2: 'Servicios',
  accept_terms_and_conditions_1: 'Al hacer clic abajo, acepto',
  accept_terms_and_conditions_2: 'Los términos y condiciones de uso',
  accept_terms_and_conditions_3: 'y confirmo que he leído',
  accept_terms_and_conditions_4: 'La política de privacidad.',
  to: 'A',
  pln: '@{value} zł',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Iniciar sesión',
  email_is_incorrect: 'E-mail incorrecto.',
  logged_out: 'Has cerrado la sesión',
  booking_dialog_date_is_already_taken: 'La fecha seleccionada no está disponible. Elige otra.',
  data_search: 'Cargando datos. Por favor, espera...',
  no_employees: 'No hay empleados',
  no_services: 'No hay servicios',
  no_categories: 'No hay categorías',
  no_bookings: 'No se han encontrado citas.',
  no_subscriptions: 'No se han encontrado suscripciones.',
  female: 'Mujer',
  male: 'Hombre',
  other: 'Otro',
  no_numeric_in_password: 'La contraseña no contiene número.',
  no_lowercase_in_password: 'La contraseña no contiene minúscula.',
  no_uppercase_in_password: 'La contraseña no contiene mayúscula.',
  no_special_character: 'La contraseña no contiene carácter especial.',
  min_n_characters: 'Longitud mínima: @{min} carácteres.',
  max_n_characters: 'Longitud máxima: @{max} carácteres.',
  email: 'E-mail',
  reset_password_fail_text: 'La contraseña no ha sido cambiada. Puede tratarse de un link caducado.',
  reset_password_success_title: 'La contraseña ha sido cambiada.',
  reset_password_success_text: 'Puedes iniciar sesión usando la nueva contraseña.',
  account_activation: 'Activación de cuenta',
  account_activation_success: 'Tu cuenta ha sido activada.',
  account_activation_fail: 'Ha habido un problema con la activación de tu cuenta. Puede que la cuenta ya está activada, o el link ha caducado.',
  account_activation_processing: 'Activación de cuenta en curso. Por favor, espera...',
  account_activation_resend_title: 'La cuenta no ha sido activada',
  account_activation_resend_text: 'Activa tu cuenta, haciendo clic en el link enviado a tu correo.',
  account_activation_resend_button: 'Enviar otra vez',
  confirm_send_activate_link_dialog_subtitle: 'Hemos enviado el link para activar tu cuenta a la dirección que habías introducido.',
  file_is_too_large: 'Archivo demasiado grande.',
  appointment_title: 'Selecciona: empleado, fecha y hora de la cita',
  others: 'Otros',
  countryLabel: 'País',
  requiredMessages: 'El número de teléfono es obligatorio',
  date_of_visit: 'Fecha',
  hour_of_visit: 'Hora',
  thank_you_for_booking: 'Tu cita',
  no_free_hours: 'No hay huecos libres para citas.',
  any_person: 'Elección aleatoria',
  password_changed: 'La contraseña ha sido cambiada',
  incorrect_old_password: 'Contraseña antigua incorrecta',
  error404: 'Error 404',
  minutes: 'min',
  hours: 'h',
  days: 'd',
  ok: 'OK',
  web_page_temporary_disabled: '¡Lo sentimos! Página temporalmente no disponible.',
  profile_update_success: 'Tus datos han sido guardados.',
  form_is_invalid: 'Se han detectado errores. Revisa los campos marcados e inténtalo de nuevo.',
  search: 'Buscar',
  pay_online_button_title: 'Pagar online',
  account_not_activated_info: 'Tu dirección de correo no está confirmada. Confírmala, haciendo clic en el link que te hemos enviado.',
  paid: 'Pagado',
  payments: 'Pagos',
  payment_service: 'Servicio',
  payment_date: 'Fecha',
  payment_price: 'Precio',
  payment_success_title: 'Pago realizado con éxito. ¡Gracias!',
  payment_fail_title: 'Pago fallido, inténtalo de nuevo.',
  pay_again: 'Pagar otra vez',
  newsletter_subscribe_success: 'Gracias. Tu dirección de correo ha sido confirmada.',
  newsletter_subscribe_fail: 'Ha habido un problema con la activación de tu cuenta. Puede que la cuenta ya está activada, o el link ha caducado.',
  newsletter_send_again: 'Enviar otra vez',
  newsletter_send_again_success: 'Un nuevo link de activación ha sido enviado.',
  send_again_fail: 'Ha habido un problema al generar un nuevo link de activación. Quizá tu correo ya está activado o es porque acabas de generar otro link hace muy poco.',
  newsletter_email_is_used: 'El e-mail ya está en la lista. Si la dirección aún no ha sido confirmada, podemos enviar un nuevo link de activación.',
  newsletter_unsubscribe_success: 'Gracias. Tu dirección de correo ha sido borrada.',
  newsletter_unsubscribe_fail: 'Ha habido un problema con la eliminación de tu e-mail. Quizá esa dirección ya estaba borrada, o el link ha caducado.',
  booking_payments: 'Citas',
  simple_store_product_transactions: 'Productos',
  payment_type_select_box: 'Seleccionar método de pago',
  payment_description: 'Descripción',
  add_to_cart: 'Añadir a la cesta',
  remove_from_cart: 'Quitar de la cesta',
  save: 'Guardar',
  bookings_cart_summary: 'Resumen de la reserva',
  today: 'Hoy',
  fill_booking_details_title: 'Rellenar formulario',
  create_an_account_question: 'Crear cuenta y enviar contraseña al e-mail',
  value_is_incorrect: 'Valor inválido.',
  first_available_date: 'Citas disponibles',
  service_location_1: 'Dirección',
  service_location_2: 'Teléfono',
  service_location_3: 'Google Meet',
  service_location_4: 'Otra',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Ubicación',
  booking_not_found: 'No se han encontrado citas.',
  booking_canceled: 'La cita ha sido cancelada.',
  booking_canceled_fail: 'La cita ya no puede ser cancelada.',
  day: 'Día',
  week: 'Semana',
  month: 'Mes',
  year: 'Año',
  booking_plural_1: 'Cita',
  booking_plural_2: 'Citas',
  booking_plural_3: 'Citas',
  booking_plural_4: 'Citas',
  booking_plural_5: 'Citas',
  day_plural_1: 'Día',
  day_plural_2: 'Días',
  day_plural_3: 'Días',
  day_plural_4: 'Días',
  day_plural_5: 'Días',
  week_plural_1: 'Semana',
  week_plural_2: 'Semanas',
  week_plural_3: 'Semanas',
  week_plural_4: 'Semanas',
  week_plural_5: 'Semanas',
  month_plural_1: 'Mes',
  month_plural_2: 'Meses',
  month_plural_3: 'Meses',
  month_plural_4: 'Meses',
  month_plural_5: 'Meses',
  year_plural_1: 'Año',
  year_plural_2: 'Años',
  year_plural_3: 'Años',
  year_plural_4: 'Años',
  year_plural_5: 'Años',
  tax: 'NIF',
  tax_prefix: 'Prefijo',
  get_company_data: 'Buscar',
  get_company_data_failed: 'Error al cargar datos.',
  company_name: 'Nombre de empresa',
  street_and_number: 'Calle y número',
  postcode: 'Código postal',
  city: 'Localidad',
  country: 'País',
  subscribe: 'Suscribir',
  recurrent_fee_info: 'A pagar cada',
  one_time_fee_info: 'Pago único.',
  new_account_title: 'Crear una cuenta nueva',
  payment_data_title: 'Datos del pago',
  billing_data_checkbox_question: 'Datos de facturación',
  confirmation_modal_header: 'Confirmación requerida',
  confirmation_modal_warning: '¿Seguro que quieres continuar?',
  no_way_to_undo_action: '¡Esta acción es irrevocable!',
  delete_payment_method: 'Borrar tarjeta',
  add_payment_method: 'Añadir tarjeta',
  fill_card_details: 'Rellena la información de la tarjeta.',
  subscriptions: 'Suscripciones',
  subscription: 'Suscripión',
  invoice_number: 'Factura',
  number: 'Número',
  status: 'Estado',
  details: 'Detalles',
  created_at: 'Creado',
  subscription_status_initiated: 'No pagada',
  subscription_status_active: 'Activa',
  subscription_status_inactive: 'Inactiva',
  subscription_status_canceled: 'Cancelada',
  ends_at: 'Vence',
  ends_at_default: 'Válida hasta el final del periodo',
  select_subscription_to_pay: 'Seleccionar suscripción para pagar.',
  pay_with_subscription: 'Pagar con suscripción',
  bookings_paid_by_subscription_success_message: 'Pagado',
  bookings_not_paid_by_subscription_error_message: 'No es posible pagar con la suscripción seleccionada.',
  stripe: 'Stripe',
  provider_tag: 'Método',
  card: 'Tarjeta',
  cash: 'Efectivo',
  transfer: 'Transferencia',
  promo_code_label: 'Código de descuento',
  promo_code_invalid_message: 'Código de descuento incorrecto.',
  payment_method_setup_error: 'Ha habido un problema con tu tarjeta. Tu banco ha bloqueado la opción de autorizar tarjeta para futuras transacciones. Contacta con el banco.',
  active_subscription_missing: 'Para reservar este servicio necesitas una suscripción activa. Parece que ahora mismo no dispones de ninguna.',
  area_code: 'Prefijo',
  usage: 'Uso',
  create_account_in_stripe_failed: 'Datos de pago rechazados por el operador. Por favor, verifica si son correctos (NIF, teléfono, e-mail).',
  additional_auth_required: 'Tu banco requiere autorización adicional',
  general_payment_error: 'Error al efectuar el pago. Quizá tu tarjeta no es válida para suscripciones. Contacta con el banco, o prueba otro método de pago.',
  subscription_set_successfully: 'Suscripción configurada con éxito.',
  booking_created: 'Cita nueva',
  booking_updated: 'Modificar cita',
  booking_deleted: 'Borrar cita',
  booking_reminder: 'Recordatorio de cita',
  email_notifications: 'Notificaciones e-mail',
  sms_notifications: 'Notificaciones SMS',
  save_user_preferences: 'Guardar configuración',
  free_spots: 'Queda',
  no: 'No',
  yes: 'Sí',
  p24_inactive: 'No se puede seleccionar Przelewy24. Prueba con otro operador.',
  stripe_customer_invalid: 'Stripe ID del cliente incorrecto. No se puede añadir método de pago.',
  stripe_resource_invalid: 'Esta opción no está disponible con este operador.',
  account_blocked_message: 'Tu cuenta actualmente está bloqueada. Contacta con el administrador.',
  time_zone: 'Huso horario',
  no_spots_left: 'Límite de plazas libres alcanzado.',
  employee_auth_required: 'Inicie sesión en su cuenta o use una dirección de correo electrónico diferente. Las cuentas de empleados requieren iniciar sesión.',
  subpage_auth_required: 'Inicie sesión para ver la ficha.',
  gross: 'La cantidad bruta.',
  subscription_login_info: '¿Ya tienes tu cuenta? Inicie sesión a continuación para cargar sus datos.',
  change_date_time: 'Reprogramar',
  promo_code_checkbox: 'Tengo un código de descuento',
  booking_promo_code_label: 'Ingrese el código de descuento',
  select_hour: 'Seleccionar hora',
  id: 'Id',
  booking_rescheduled: 'Su cita ha sido cambiada.',
  booking_rescheduled_fail: 'Su cita no se puede cambiar.',
  max_user_bookings_error: 'Ha superado el número máximo de reservas. Elija otro servicio o póngase en contacto con nosotros.',
  in_total: 'Total',
  company: 'Empresa',
  individual: 'Persona privada',
  bulk_payment: 'El pago a granel',
  make_bulk_payment: 'El pago a granel',
  simple_store_product_confirmation_title: 'Complete el formulario de compra',
  select_service_type: 'Seleccione una opción de servicio',
  add_to_calendar_button_label: 'Añadir al Calendario',
  login_to_use_service_with_subscription: 'Inicie sesión en su cuenta para reservar una cita para este servicio.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'La fecha seleccionada no está disponible. Por favor, elija otra fecha libre. En caso de reservar varias fechas, asegúrese de que no se excluyan mutuamente.',
  select_location_calendar_warning: 'Seleccione una ubicación para cargar los horarios disponibles.',
  selectable_location_online_title: 'En línea',
  change: 'Cambiar',
  show_less_hours_button: 'Mostrar menos horas',
  show_more_hours_button: 'Mostrar más horas',
  add_another_button_title: 'Agregar otro',
  close: 'Cerrar',
  selected_time_slots: 'Franjas horarias seleccionadas',
  select_location: 'Seleccione ubicación',
  captcha_invalid_error_notification: 'La verificación de captcha no es válida. Intenta recargar la página si el problema persiste.',
  verify_code_incorrect_error: 'El código introducido es incorrecto.',
  verify_code_too_many_requests: 'Actualmente no podemos generar un nuevo código. Inténtalo de nuevo más tarde o utiliza el código que te hemos enviado.',
  send_again_button_title: 'Enviar de nuevo',
  verify_title: 'Verificar',
  verify_code_title: 'Introducir código de verificación',
  verify_code_description: 'Hemos enviado un código de verificación de 6 dígitos a tu correo electrónico. Por favor, introdúcelo a continuación:',
  verification_invalid_error_notification: 'Verificación no válida. Inténtalo de nuevo más tarde.',
  too_many_requests: '¡Ups! Estás actuando un poco demasiado rápido. Reduce la velocidad y vuelve a intentarlo en breve.'
}
